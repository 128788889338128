import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { IconFolder } from '@components/icons';
import { theme, mixins, media, Section, Heading } from '@styles';

const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  text-align: center;
  position: relative;
`;
const StyledHeading = styled(Heading)`
  text-align: center;
  display: block;
  color: ${colors.green};
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  margin-bottom: 20px;
  justify-content: center;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  &:before {
    bottom: 0;
    font-size: ${fontSizes.sm};
    ${media.desktop`font-size: ${fontSizes.smish};`};
  }
  &:after {
    display: none;
  }
`;
const StyledTitle = styled.h4`
  text-align: center;
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const StyledBlogSection = styled.section`
  text-align:left;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .blog-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    position: relative;
    margin-top: 50px;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  .more-button {
    ${mixins.button};
    margin: 80px auto 0;
  }
`;

const StyledBlog = styled.div`
  cursor: default;
  transition: var(--transition);

  &:hover,
  &:focus {
    outline: 0;
    .blog-inner {
      transform: translateY(-5px);
    }
  }

  .blog-inner {
    ${mixins.boxShadow};
    ${mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
  }

  .blog-top {
    ${mixins.flexBetween};
    margin-bottom: 30px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .blog-links {
      margin-right: -10px;
      color: var(--light-slate);

      a {
        padding: 5px 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .blog-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);
  }

  .blog-description {
    color: var(--light-slate);
    font-size: 17px;

    a {
      ${mixins.inlineLink};
    }
  }

  .blog-tech-list {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const Blog = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const revealContainer = useRef(null);
  const revealTitle = useRef(null);
  const revealBlogLink = useRef(null);
  const revealBlog = useRef([]);

  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealBlogLink.current, srConfig());
    revealBlog.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const Blog = data.filter(({ node }) => node);
  const firstSix = Blog.slice(0, GRID_LIMIT);
  const BlogToShow = showMore ? Blog : firstSix;

  return (
    <StyledContainer id="blog" ref={revealContainer}>
      <StyledHeading>Blog.</StyledHeading>
      <StyledTitle>Some Things I've Written.</StyledTitle>
      <StyledBlogSection>
        <TransitionGroup className="blog-grid">
          {BlogToShow &&
            BlogToShow.map(({ node }, i) => {
              const { frontmatter } = node;
              const { title, description, date, slug } = frontmatter;

              return (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledBlog
                    key={i}
                    ref={el => (revealBlog.current[i] = el)}
                    tabIndex="0"
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    <div className="blog-inner">
                      <header>
                        <div className="blog-top">
                          <div className="folder">
                            <IconFolder />
                          </div>
                        </div>

                        <h3 className="blog-title">{title}</h3>

                        <div className="blog-description" >
                          <p>{date}</p>
                          <p>{description}</p>
                        </div>
                      </header>

                      <Link className="inline-link blog-link" to={slug}>
                        Read more...
                      </Link>
                    </div>
                  </StyledBlog>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
        {
          BlogToShow.length > 6 ?
            <button className="more-button" onClick={() => setShowMore(!showMore)}>
              Show {showMore ? 'Less' : 'More'}
            </button>
            :
            <></>
        }
        <br />
        <Link className="inline-link blog-link" to="/blog" ref={revealBlogLink}>
          See all.
        </Link>
      </StyledBlogSection>
    </StyledContainer >
  );
};

export default Blog;
