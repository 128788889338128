import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mixins } from '@styles';

const StyledContainer = styled.footer`
  ${mixins.flexCenter};
  flex-direction: column;
  padding: 15px;
  text-align: center;
  height: auto;
  min-height: 70px;
`;

const StyledCredit = styled.div`
  color: var(--slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1;

  a {
    color: var(--slate);
    
    :after {
      background-color: var(--lightNavy);
    }

    :hover {
      color: var(--slate);
    }
  }
`;

const Footer = () => {
  return (
    <StyledContainer>
      <StyledCredit tabindex="-1">
        <div>
          <p>I am no web developer</p>
          <p>
            Theme by <a href="https://brittanychiang.com">Brittany Chiang</a>
          </p>
        </div>
      </StyledCredit>
    </StyledContainer>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
