import React from 'react';

const IconLoader = () => (
<svg id="logo" viewBox="0 0 100 100">
  <title>Loader Logo</title>
  <g>
    <g id="J" transform="translate(11.000000, 5.000000)">
      <path 
        d="M 37.298 60.953 C 35.218 60.953 33.398 60.6 31.838 59.893 C 30.271 59.193 29.038 58.116 28.138 56.663 C 27.245 55.216 26.758 53.38 26.678 51.153 L 26.718 51.023 L 32.338 51.023 C 32.445 52.983 32.881 54.35 33.648 55.123 C 34.415 55.89 35.435 56.273 36.708 56.273 C 37.528 56.273 38.248 56.096 38.868 55.743 C 39.488 55.39 39.981 54.86 40.348 54.153 C 40.708 53.453 40.888 52.59 40.888 51.563 L 40.888 34.893 L 36.868 34.273 L 36.868 30.663 L 50.058 30.663 L 50.058 34.273 L 46.858 34.893 L 46.858 51.563 C 46.858 53.476 46.488 55.136 45.748 56.543 C 45.008 57.95 43.928 59.036 42.508 59.803 C 41.088 60.57 39.351 60.953 37.298 60.953 Z" 
        fill="currentColor"
      />
    </g>
    <path 
      stroke="currentColor" 
      strokewidth="5" 
      strokelinecap="round" 
      strokelinejoin="round" 
      d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"></path>
  </g>
</svg>
);

export default IconLoader;
